import React, { Component } from "react";
import axios from "axios";
import { Container, Row, Col } from 'react-bootstrap';
import { InfoCircle } from 'react-bootstrap-icons';
import { CheckPicker, Button, Input, Checkbox, SelectPicker, CheckboxGroup, Loader, Message, Modal, Tooltip, Whisper } from 'rsuite';
import { strokovnoPodrocje, jeziki, abeceda } from "./data";
import DisplayData from "./DisplayData";
import 'bootstrap/dist/css/bootstrap.min.css';
import 'rsuite/dist/rsuite.min.css';
import './App.css';

// const baseURL = "http://localhost:5000/express_backend";
const baseURL = "https://iskalnik.dztps.si/express_backend";

class App extends Component {
    constructor() {
        super();
        this.state = {
			loading: true,
			firstLoad: true,
			tablePage: 1,
			tableData: [],
			tableDataGenerated: [],
			iskanjeNiz : "",
			iskanjePoPoljih : ["ime", "priimek", "strokovnopodrocje", "prevodi"],
			iskanjeStrokovnoPodrocje: [],
			iskanjeRang: ["A", "B", "C", "D", "E", "F", "G", "H"],
			iskanjeVloga: [],
			iskanjeLicenca: false,
			iskanjeIzvirniJezik: "",
			iskanjeCiljniJezik: "",
			iskanjeCrkaPriimek: "",
			modalNavodilaOpen: false,
        };
    }

	componentDidMount() {
        // Call our fetch function below once the component mounts
        this.callBackendAPI().then(res => console.log(res.express)).catch(err => console.log(err));
		axios.get(`${baseURL}/api/getAll`).then(result => {
			this.setState({
				tableDataGenerated: result.data
			});
			this.setState({
				loading: false
			});
		})
		// this.loadAllData();
		// setTimeout(function() {
		// 	this.setState({
		// 		loading: false,
		// 		tableDataGenerated: this.state.tableData,
		// 		tableData: []
		// 	})
		// }.bind(this), 750)
    };

	componentDidUpdate() {
	}

	// loadAllData() {
	// 	return axios.get("https://iskalnik.dztps.si/express_backend/api/getAll")
	// 		.then(result => {
	// 			this.setState({
	// 				tableData: result.data
	// 			});
	// 		}).catch(error => {
	// 			console.error("error: ", error);
	// 		});
	// }

	loadAllData() {
		axios.get(`${baseURL}/api/getAll`).then(result => {
			this.setState({
				tableDataGenerated: result.data
			});
			this.setState({
				loading: false
			});
		})
	}

	loadData = async () => {
		return axios.get(`${baseURL}/api/get`)
			.then(result => {
				// console.log(result);
				this.setState({
					tableData: result.data
				});
			  })
			  .catch(error => {
				console.error("error: ", error);
			  });
	}

	callBackendAPI = async () => {
        const response = await fetch(`${baseURL}`);
        const body = await response.json();
    
        if (response.status !== 200) {
            throw Error(body.message) 
        }
        return body;
    };

	handleClick() {
		this.setState({
			loading: true,
			firstLoad: false,
		})
		const searchData = {
			iskanjeNiz : this.state.iskanjeNiz,
			iskanjeStrokovnoPodrocje: this.state.iskanjeStrokovnoPodrocje,
			iskanjeRang: this.state.iskanjeRang,
			iskanjeLicenca: this.state.iskanjeLicenca ? "1" : "0",
			iskanjeVloga: this.state.iskanjeVloga,
			iskanjeIzvirniJezik: this.state.iskanjeIzvirniJezik,
			iskanjeCiljniJezik: this.state.iskanjeCiljniJezik,
			iskanjeCrkaPriimek: this.state.iskanjeCrkaPriimek
		};
		// console.log("json:" + JSON.stringify(searchData))
		axios.post(`${baseURL}/api/v1/iskanje`, searchData)
			.then(response => {

				//console.log(response.data)
				this.setState({
					tableDataGenerated: response.data,
				})
				this.setState({
					loading: false
				})
			});
	}

	handleReset() {
		this.setState({
			loading: true,
			firstLoad: false,
			iskanjeNiz : "",
			iskanjeStrokovnoPodrocje: [],
			iskanjeRang: [],
			iskanjeVloga: [],
			iskanjeLicenca: false,
			iskanjeIzvirniJezik: "",
			iskanjeCiljniJezik: "",
			iskanjeCrkaPriimek: ""
		})
		this.setState({
			loading: false,
		})
		this.loadAllData();
		this.setState({
			loading: false
		})
		this.setState({
			tableDataGenerated: this.state.tableData
		})
		this.setState({
			tableData: []
		})
	}

	_handleKeyDown = (e) => {
		if (e.key === 'Enter') {
			this.handleClick();
		}
	}

    render() {
		const locale = {
			searchPlaceholder: 'Iskanje ...'
		};
		
        return (
			<div className="App">
				<Container>
					<Row>
						<h1 style={{textAlign: "center"}}>Iskalnik po članih</h1>
						<Row>
							<Message type="info">
								Dobrodošli na spletnem iskalniku po članih Društva znanstvenih in tehniških prevajalcev Slovenije. Za navodila za uporabo iskalnika kliknite <Button appearance="ghost" color="cyan" onClick={() => this.setState({modalNavodilaOpen: true})}>tukaj</Button>.
							</Message>
							<Modal open={this.state.modalNavodilaOpen} size="lg" onClose={() => this.setState({modalNavodilaOpen: false})}>
								<Modal.Header>
									<Modal.Title>Navodila za uporabo iskalnika</Modal.Title>
								</Modal.Header>
								<Modal.Body>
									<p>Iskalnik omogoča iskanje prevajalk in prevajalcev po različnih parametrih. Prikaz vseh prevajalk in prevajalcev je urejen po abecednem vrstnem redu.</p> <br /><br />
									<p>Za iskanje po imenu in/ali priimku vpišite ime oziroma priimek iskane prevajalke/prevajalca.</p>
									<p><b>Iskanje po prvi črki priimka:</b> Izberite črko s seznama. Prikazani bodo vsi, ki ustrezajo izbranim kriterijem.</p>
									<p><b>Strokovno področje:</b> Na seznamu lahko izberete eno ali več strokovnih področij za izbrano smer prevajanja.</p>
									<p><b>Prevajanje iz oz. v jezik:</b> Izberete lahko samo izvorni jezik, samo ciljni jezik ali oba.</p>
									<p><b>Licenca:</b> Licenca je listina, ki potrjuje usposobljenost za prevajanje iz izvornega v ciljni jezik. Društvo jo izdaja tistim prevajalcem, ki izpolnjujejo pogoje društva za pridobitev licence društva ali uspešno opravijo preizkus usposobljenosti.</p>
									<p><b>Dodatne storitve:</b> Izberete lahko storitve tolmačenja, lektoriranja in sodnega tolmačenja za izbrano smer prevajanja.</p>
									<p>Privzeto se v tabeli prikaže 30 prevajalk in prevajalcev. Število izpisov na stran lahko prilagodite s klikom na gumb "30". Na naslednjo oziroma prejšnjo stran se lahko premaknete s klikom na puščico <span class="rs-pagination-symbol"><svg width="1em" height="1em" viewBox="0 0 7 14" fill="currentColor" aria-hidden="true" focusable="false" class="rs-icon" aria-label="page previous" data-category="legacy"><path d="M2.333 7l3.5-4-.583-.667L1.167 7l4.083 4.667.583-.667z"></path></svg></span> ali <span class="rs-pagination-symbol"><svg width="1em" height="1em" viewBox="0 0 7 14" fill="currentColor" aria-hidden="true" focusable="false" class="rs-icon" aria-label="page next" data-category="legacy"><path d="M4.667 7l-3.5 4 .583.667L5.833 7 1.75 2.333 1.167 3z"></path></svg></span> ali pa s klikom na številko strani.</p><br /><br />
									<p>Za iskanje kliknite gumb "<b>Išči</b>", za ponastavitev iskanja pa kliknite "<b>Počisti</b>".</p>
								</Modal.Body>
								<Modal.Footer>
									<Button appearance="primary" onClick={() => this.setState({modalNavodilaOpen: false})}>
										Zapri
									</Button>
								</Modal.Footer>
							</Modal>
						</Row>
						<div className="iskalnik">
							<Row>
								<Col>
									<h6>Iskalni niz:</h6>
									<Input
										name="iskanjeNiz"
										placeholder="Iskanje po imenu in/ali priimku prevajalca"
										value={this.state.iskanjeNiz}
										onChange={(value) => {this.setState({iskanjeNiz: value})}}
										onKeyDown={this._handleKeyDown}
										
									/>
									<h6>Iskanje po prvi črki priimka</h6>
									<SelectPicker
										className="iskanjePriimek"
										searchable={false}
										value={this.state.iskanjeCrkaPriimek}
										onChange={(value) => {this.setState({iskanjeCrkaPriimek: value})}}
										data={abeceda}
										style={{width: "100%"}}
										placeholder="Izberite črko"
										required
									/>
									<h6>Strokovno področje:</h6>
									<CheckPicker
										locale={locale}
										name="iskanjeStrokovnoPodrocje"
										value={this.state.iskanjeStrokovnoPodrocje}
										onChange={(value) => {this.setState({iskanjeStrokovnoPodrocje: value})}}
										data={strokovnoPodrocje}
										style={{ width: "100%" }}
										placeholder="Strokovno področje"
									/>
								</Col>
								<Col>
									<div>
										<div className="flex-row">
											<div style={{paddingRight: "1rem", width: "100%"}}>
												<h6>Prevajanje iz jezika</h6>
												<SelectPicker
													locale={locale}
													value={this.state.iskanjeIzvirniJezik}
													onChange={(value) => {
														this.setState({iskanjeIzvirniJezik: value})
													}}
													data={jeziki}
													style={{width: "100%"}}
													placeholder="Izberite izvorni jezik"
													required
												/>
											</div>
											<div style={{paddingRight: "1rem", width: "100%"}}>
												<h6>Prevajanje v jezik</h6>
												<SelectPicker
													locale={locale}
													value={this.state.iskanjeCiljniJezik}
													onChange={(value) => {
														this.setState({iskanjeCiljniJezik: value})
													}}
													data={jeziki}
													style={{width: "100%"}}
													placeholder="Izberite ciljni jezik"
													required
												/>
											</div>
										</div>
										<h6>Dodatne storitve:</h6>
										<div>
											<CheckboxGroup
												name="iskanjeVloga"
												inline
												value={this.state.iskanjeVloga}
												onChange={(value) => {
													this.setState({iskanjeVloga: value})
												}}
												style={{flexWrap: "wrap"}}
											>
												<Checkbox value="lektorica/lektor">Lektorica/Lektor</Checkbox>
												<Checkbox value="tolmačka/tolmač">Tolmačka/Tolmač</Checkbox>
												<Checkbox value="sodna tolmačka/sodni tolmač">Sodna tolmačka/Sodni
													tolmač</Checkbox>
											</CheckboxGroup>
										</div>
										<h6>Ima licenco DZTPS: <Whisper followCursor speaker={<Tooltip>Licenca je listina, ki potrjuje usposobljenost za prevajanje iz izvornega v ciljni jezik. Društvo jo izdaja tistim prevajalcem, ki izpolnjujejo pogoje društva za pridobitev licence društva ali uspešno opravijo preizkus usposobljenosti.</Tooltip>}>
											<InfoCircle size={14} />
										</Whisper></h6>

										<div>
											<Checkbox
												name="iskanjeLicenca"
												checked={this.state.iskanjeLicenca}
												value="DA"
												onChange={() => this.setState({iskanjeLicenca: !this.state.iskanjeLicenca})}
											>
												DA
											</Checkbox>
											{/*<CheckboxGroup*/}
											{/*	name="iskanjeLicenca"*/}
											{/*	inline*/}
											{/*	value={this.state.iskanjeLicenca}*/}
											{/*	onChange={(value) => {*/}
											{/*		this.setState({iskanjeLicenca: value})*/}
											{/*	}}*/}
											{/*	style={{flexWrap: "wrap"}}*/}
											{/*>*/}
											{/*	<Checkbox value="DA">DA</Checkbox>*/}
											{/*</CheckboxGroup>*/}
										</div>
									</div>
									<div style={{display: "flex", marginTop: "1rem", marginBottom: "1rem"}}>
										<Button style={{margin: "5px"}} size="lg" className={""} appearance="primary"
												onClick={() => this.handleClick()}
												disabled={((this.state.iskanjeCiljniJezik === this.state.iskanjeIzvirniJezik) && ((this.state.iskanjeCiljniJezik !== "") && (this.state.iskanjeIzvirniJezik !== "")) && ((this.state.iskanjeCiljniJezik !== null) && (this.state.iskanjeIzvirniJezik !== null))) ? true : false} block>Išči</Button>
										<Button style={{margin: "5px"}} size="lg" className={""} appearance="primary" color="yellow" onClick={() => this.handleReset()} block>Počisti</Button>
									</div>
								</Col>
							</Row>
							<Row style={{marginTop: "1.5rem"}}>
								<Col>
									{((this.state.iskanjeCiljniJezik === this.state.iskanjeIzvirniJezik) && ((this.state.iskanjeCiljniJezik !== "") && (this.state.iskanjeIzvirniJezik !== "")) && ((this.state.iskanjeCiljniJezik !== null) && (this.state.iskanjeIzvirniJezik !== null))) && <Message showIcon type="warning">
											Ciljni in izvirni jezik ne moreta biti enaka.
										</Message>}
								</Col>
							</Row>
						</div>
					</Row>
				</Container>
				<Container>
					<Row>
						{this.state.loading ?
							<Loader style={{display: "flex", justifyContent: "center", padding: "1rem 0"}} size="lg" /> :
						<>
							<Col>
								<Message style={{marginBottom: "1rem"}} showIcon type="success">
									Najdenih zadetkov: {this.state.tableDataGenerated.length ? this.state.tableDataGenerated.length : 0}
								</Message>
							</Col>
							<DisplayData firstLoad={this.state.firstLoad} data={this.state.tableDataGenerated} iskanjeStrokovnoPodrocje={this.state.iskanjeStrokovnoPodrocje} iskanjeVloga={this.state.iskanjeVloga} />
						</>
						}
					</Row>
				</Container>
				<Container fluid style={{paddingTop: "1rem", paddingBottom: "1rem", backgroundColor: "#f6f6f6", marginTop: "1rem"}}>
					<Container>
						<Row>
							<Col>
								<p>&copy; {new Date().getFullYear()} | Društvo znanstvenih in tehniških prevajalcev Slovenije</p>
							</Col>
							<Col style={{display: "flex", justifyContent: "flex-end"}}>
								<p>Made with {`<heart>`} | <a href="https://dpoint.si">dpoint.si</a></p>
							</Col>
						</Row>
					</Container>
				</Container>
     		</div>
        );
    }
}

export default App;
